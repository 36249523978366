$icon-xxl: 8rem;
$icon-xl: 6.4rem;
$icon-lg: 5.2rem;
$icon-md: 4rem;
$icon-sm: 3.2rem;
$icon-xs: 2.4rem;
$icon-xxs: 1.8rem;
$icon-xxxs: 1.4rem;
$icon-small: 1rem;
$icon-title: 4rem;

$size-desktop-xxl: 8rem;
$size-desktop-xl: 6.4rem;
$size-desktop-lg: 5.2rem;
$size-desktop-md: 4rem;
$size-desktop-sm: 3.2rem;
$size-desktop-xs: 2.4rem;
$size-desktop-xxs: 1.8rem;
$size-desktop-xxxs: 1.4rem;
$size-desktop-small: 1rem;
$size-desktop-title: 5rem;

.icon-theme {
  line-height: 0;
  svg {
    g,
    circle,
    ellipse,
    line,
    polygon,
    polyline,
    rect,
    text,
    path,
    tspan {
      &.fill {
        fill: map-get($color-palette, 'icon-theme-color');
      }

      &.stroke {
        stroke: map-get($color-palette, 'icon-theme-color');
      }
    }
  }
}

.blue-icon {
  line-height: 0;
  svg {
    g,
    circle,
    ellipse,
    line,
    polygon,
    polyline,
    rect,
    text,
    path,
    tspan {
      &.fill {
        fill: map-get($color-palette, 'dark-blue') !important;
      }

      &.stroke {
        stroke: map-get($color-palette, 'dark-blue') !important;
      }
    }
  }
}

.green-icon {
  line-height: 0;
  svg {
    g,
    circle,
    ellipse,
    line,
    polygon,
    polyline,
    rect,
    text,
    path,
    tspan {
      &.fill {
        fill: map-get($color-palette, 'green') !important;
      }

      &.stroke {
        stroke: map-get($color-palette, 'green') !important;
      }
    }
  }
}

@each $name, $color in $color-palette {
  .icon-#{$name} {
    svg {
      g,
      circle,
      ellipse,
      line,
      polygon,
      polyline,
      rect,
      text,
      path,
      tspan {
        &.fill {
          fill: $color;
        }

        &.stroke {
          stroke: $color;
        }
      }
    }
  }
}

@mixin iconSize($size, $size-desktop) {
  svg,
  img {
    height: $size;
    width: $size;
    @include respond-to(xl) {
      height: $size-desktop;
      width: $size-desktop;
    }
  }
}

.icon-xxl {
  @include iconSize($icon-xxl, $size-desktop-xxl);
}

.icon-xl {
  @include iconSize($icon-xl, $size-desktop-xl);
}

.icon-lg {
  @include iconSize($icon-lg, $size-desktop-lg);
}

.icon-md {
  @include iconSize($icon-md, $size-desktop-md);
}

.icon-sm {
  @include iconSize($icon-sm, $size-desktop-sm);
}

.icon-xs {
  @include iconSize($icon-xs, $size-desktop-xs);
}

.icon-xxs {
  @include iconSize($icon-xxs, $size-desktop-xxs);
}

.icon-xxxs {
  @include iconSize($icon-xxxs, $size-desktop-xxxs);
}

.icon-small {
  @include iconSize($icon-small, $size-desktop-small);
}

.icon-title {
  @include iconSize($icon-title, $size-desktop-title);
}

.icon-xs-responsive {
  svg,
  img {
    height: $icon-xs;
    width: $icon-xs;

    @media screen and (max-width: map-get($breakpoints, 'sm')) {
      height: $icon-xxs;
      width: $icon-xxs;
    }
  }
}
