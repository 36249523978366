$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weight-base: $font-weight-light;
$font-weight-header: $font-weight-light;
$font-weight-button: $font-weight-bold;

$font-family-base: 'Roboto', sans-serif;
$font-family-header: 'Roboto', sans-serif;

$font-size-base: 1.6rem;
$font-size-h1: 2.8rem;
$font-size-h1-desktop: 3.6rem;
$font-size-h2: $font-size-base;
$font-size-h2-desktop: 2rem;
$font-size-h3: 2rem;
$font-size-h4: 2.4rem;
$font-size-h5: 2.2rem;
$font-size-h6: 1.8rem;
$font-size-xsmall: 1.2rem;
$font-size-small: 1.4rem;
$font-size-medium: 2rem;
$font-size-button: 2.2rem;
$font-letter-spacing: 0.1rem;

$line-height-base: 2.4rem;
$line-height-small: 2.2rem;
$line-height-medium: 2.8rem;
$line-height-h1: 3.8rem;
$line-height-h1-desktop: 4.6rem;
$line-height-h2: 2.8rem;
$line-height-h3: 2.4rem;
$line-height-h4: 2.8rem;
$line-height-h5: 2.8rem;
$line-height-h6: $line-height-base;

// Design refresh
$refresh-font-size-p: 1.8rem;
$refresh-line-height-p: 2.8rem;
$refresh-font-size-h1: 3.2rem;
$refresh-line-height-h1: 4rem;
$refresh-font-size-h3: 2rem;
$refresh-line-height-h3: 3rem;
$refresh-line-height-small: 1.8rem;

@mixin originalTypography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-header;
    font-weight: $font-weight-header;
    margin-bottom: $xs;
    @include respond-to(xl) {
      margin-bottom: $sm;
    }
  }

  h1,
  .h1 {
    font-size: $font-size-h1;
    @include respond-to(xl) {
      font-size: $font-size-h1-desktop;
    }
  }
  h2,
  .h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-medium;
    @include respond-to(xl) {
      font-size: $font-size-h2-desktop;
      font-weight: $font-weight-header;
    }
  }
  h3,
  .h3 {
    font-size: $font-size-h3;
  }
  h4,
  .h4 {
    font-size: $font-size-h4;
  }
  h5,
  .h5 {
    font-size: $font-size-h5;
  }
  h6,
  .h6 {
    font-size: $font-size-h6;
  }

  h1 {
    @include respond-to(xl) {
      line-height: $line-height-h1-desktop;
    }
    line-height: $line-height-h1;
    color: map-get($color-palette, 'h1-color');
  }

  h2 {
    line-height: $line-height-h2;
    color: map-get($color-palette, 'h2-color');
  }

  h3 {
    line-height: $line-height-h3;
    color: map-get($color-palette, 'h3-color');
  }

  h4 {
    line-height: $line-height-h4;
    color: map-get($color-palette, 'h4-color');
  }

  h5 {
    line-height: $line-height-h5;
    color: map-get($color-palette, 'h5-color');
  }

  h6 {
    line-height: $line-height-h6;
    color: map-get($color-palette, 'h6-color');
  }

  .xsmall {
    font-size: $font-size-xsmall;
    line-height: $line-height-small;
  }

  small,
  .small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  .medium {
    font-size: $font-size-medium;
    line-height: $line-height-medium;
  }

  a {
    font-weight: $font-weight-medium;
    color: map-get($color-palette, 'highlight-color');
  }

  label {
    font-weight: $font-weight-regular;
    color: map-get($color-palette, 'highlight-color');
  }

  .underline {
    text-decoration: underline;
  }

  .no-decoration {
    text-decoration: none !important;
  }

  .regular {
    font-weight: $font-weight-regular;
  }

  .color-error {
    color: map-get($color-palette, 'error-red');
  }

  p {
    line-height: $line-height-base;
  }

  .disable-text-selection {
    user-select: none;
  }
}

@include originalTypography;

// Refresh of designs
.design-refresh {
  @include originalTypography;
  p {
    font-size: $refresh-font-size-p;
    line-height: $refresh-line-height-p;
  }
  h1 {
    font-size: $refresh-font-size-h1;
    line-height: $refresh-line-height-h1;
  }
  h3 {
    font-size: $refresh-font-size-h3;
    line-height: $refresh-line-height-h3;
  }
  a {
    font-weight: $font-weight-regular;
    color: map-get($color-palette, 'highlight-color');
    text-decoration: none;
  }
  .xsmall {
    font-size: $font-size-xsmall;
    line-height: $line-height-small;
  }
}
