.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Will only truncate long words without break
.truncate-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-weight-light {
  font-weight: $font-weight-light;
}
.font-weight-regular {
  font-weight: $font-weight-regular;
}
.font-weight-medium {
  font-weight: $font-weight-medium;
}
.font-weight-bold {
  font-weight: $font-weight-bold;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-start {
  text-align: start;
}
.text-align-end {
  text-align: end;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-align-initial {
  text-align: initial;
}
.text-align-inherit {
  text-align: inherit;
}

.font-weight-light {
  font-weight: $font-weight-light;
}
.font-weight-regular {
  font-weight: $font-weight-regular;
}
.font-weight-bold {
  font-weight: $font-weight-medium;
}
.font-weight-heavy {
  font-weight: $font-weight-bold;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-start {
  text-align: start;
}
.text-align-end {
  text-align: end;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-align-initial {
  text-align: initial;
}
.text-align-inherit {
  text-align: inherit;
}
