.ng-invalid[class~='ng-touched'] {
  border: 1px solid map-get($color-palette, 'error-red');
}

.invalid-field {
  color: map-get($color-palette, 'error-red');
}

// Remove arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
