.centered-form {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  section {
    width: 100%;
  }
}

.invalid-form-submission {
  color: $error-color;
  font-weight: bold;
  min-height: $line-height-h6;
  text-align: center;
}

.radio-button-text > label {
  font-weight: $font-weight-regular;
  color: map-get($color-palette, 'highlight-color');
}
