@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@include mat.strong-focus-indicators(
  (
    border-style: dotted,
    border-width: 4px,
    border-radius: 2px,
  )
);

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

@include mat.all-legacy-component-themes($my-theme);
@include mat.strong-focus-indicators-theme(purple);

// Direct class adjustments for date picker
.mat-form-field {
  width: 187px;
  background: transparent;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: transparent;
}

// Setting font size to 1.2rem causes spacing issue
.mat-date-range-input-mirror {
  min-width: 8px !important;
}
