:root {
  --blue: #0081a6;
  --light-blue: #a8b6d1;
  --medium-blue: var(--blue);
  --bright-blue: #26478d;
  --dark-blue: #2a498c;
  --link-blue: #0058e6;
  --purple: #6d2077;
  --light-purple: #e2d2e4;
  --pink: #b9337c;
  --medium-pink: #d82b80;
  --light-pink: #fad7e7;
  --soft-pink: #faf2f8;
  --red: #e4002b;
  --bright-red: var(--red);
  --medium-red: #d63e3e;
  --orange: #ff8f1c;
  --brown: brown;
  --yellow: #fedb00;
  --amber: #e29935;
  --green: #009f4d;
  --bright-green: #5cce5c;
  --light-green: #b7bf10;
  --turquoise: #00b2a9;
  --grey: #4a4a4a;
  --light-grey: #e2e2e2;
  --medium-grey: #3b3a48;
  --dark-grey: #222131;
  --off-white: #f9f9f9;
  --white: #fff;
  --cloud-white: #e2e2e2;
  --black: #000000;
  --shadow-blue-color: #00255626;
  --sapphire: #469d56;
  --chrome-white: #e0f3e4;
  --sea-mist: #c1dac6;
  --rose-white: #fdf8eb;
  --almond: #f0e2ca;
  --pale-pink: #f3e0e0;
  --soft-amber: #dac1c1;
  --panel-color: #e7eaee;
  --medium-red-violet: #ba2f7d;
  --timber-wolf: #d7d7d7;
  --dim-gray: #5c5c5c;
  --pantone: #848484;

  --body-bg: var(--off-white);
  --body-color: var(--medium-grey);
  --main-bg: var(--white);
  --main-color: var(--black);
  --content-bg: var(--off-white);
  --content-color: var(--grey);
  --primary: var(--dark-blue);
  --secondary: var(--pink);
  --error-red: var(--bright-red);
  --warning-orange: var(--orange);
  --success: var(--green);
  --info: var(--blue);
  --shadow-color: rgba(74, 74, 74, 0.4); //--grey
  --border-color: var(--light-blue);
  --highlight-color: var(--primary);
  --h1-color: var(--dark-blue);
  --h2-color: var(--dark-blue);
  --h3-color: var(--dark-blue);
  --h4-color: var(--dark-blue);
  --h5-color: var(--dark-blue);
  --h6-color: var(--dark-blue);
  --top-bar-border: var(--light-grey);
  --top-bar-color: var(--grey);
  --top-bar-bg: var(--content-bg);
  --top-bar-icon: var(--light-blue);
  --visited-bg: var(--off-white);
  --visited-color: var(--grey);
  --active-color: var(--white);
  --icon-theme-color: var(--secondary);
  // As RGB for rgba()
  --red-rgb: 228, 0, 43;
  --light-grey-rgb: 226, 226, 226;
  --border-color-rgb: 168, 182, 209;
  --dark-green: #007c41;
  --dark-red: #c20000;
  --label-color: #575757;
  --subHeading: #222529;
  --grey-black: #35373b;
  --dark-grey-black: #4e4e4e;
}

// Library utils
@import 'colors';
@import 'breakpoints';
@import 'icons';
@import 'spacing';
@import 'typography';
@import 'borders';
@import 'elements';
@import 'flex';
@import 'cursors';
@import 'text';
@import 'validations';
@import 'z-index';

// portal variables
@import '../variables/colour';
@import '../variables/typography';

@import '../global';
@import '../layout';

// Imports from NG library
@import '../form';

// Globaly used classes
@import '../status-circle';

// Tooltips
@import '../tooltips';

// Angular material
@import '../themes/angular-material.scss';
