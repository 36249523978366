ul {
  list-style: none; /* Remove default bullets */
  li {
    right: -$sm;
    position: relative;
    width: calc(100% - 1.25rem);
    &:before {
      position: absolute;
      left: -$sm;
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: map-get($color-palette, 'secondary'); /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1rem; /* Also needed for space (tweak if needed) */
    }
  }
}

hr {
  height: 1px;
  border: 0px;
  background: map-get($color-palette, 'light-blue');
}

.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
