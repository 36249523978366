$color-palette: (
  'transparent': transparent,
  'blue': var(--blue),
  'light-blue': var(--light-blue),
  'medium-blue': var(--medium-blue),
  'bright-blue': var(--bright-blue),
  'dark-blue': var(--dark-blue),
  'link-blue': var(--link-blue),
  'purple': var(--purple),
  'light-purple': var(--light-purple),
  'pink': var(--pink),
  'medium-pink': var(--medium-pink),
  'light-pink': var(--light-pink),
  'soft-pink': var(--soft-pink),
  'red': var(--red),
  'bright-red': var(--bright-red),
  'orange': var(--orange),
  'brown': var(--brown),
  'yellow': var(--yellow),
  'green': var(--green),
  'bright-green': var(--bright-green),
  'light-green': var(--light-green),
  'turquoise': var(--turquoise),
  'grey': var(--grey),
  'light-grey': var(--light-grey),
  'medium-grey': var(--medium-grey),
  'dark-grey': var(--dark-grey),
  'off-white': var(--off-white),
  'white': var(--white),
  'black': var(--black),
  'primary': var(--primary),
  'secondary': var(--secondary),
  'error-red': var(--error-red),
  'warning-orange': var(--warning-orange),
  'success': var(--success),
  'info': var(--info),
  'body-bg': var(--body-bg),
  'body-color': var(--body-color),
  'main-bg': var(--main-bg),
  'main-color': var(--main-color),
  'content-bg': var(--content-bg),
  'content-color': var(--content-color),
  'shadow-color': var(--shadow-color),
  'border-color': var(--border-color),
  'highlight-color': var(--highlight-color),
  'h1-color': var(--h1-color),
  'h2-color': var(--h2-color),
  'h3-color': var(--h3-color),
  'h4-color': var(--h4-color),
  'h5-color': var(--h5-color),
  'h6-color': var(--h6-color),
  'top-bar-border': var(--top-bar-border),
  'top-bar-color': var(--top-bar-color),
  'top-bar-bg': var(--top-bar-bg),
  'top-bar-icon': var(--top-bar-icon),
  'visited-bg': var(--visited-bg),
  'visited-color': var(--visited-color),
  'active-color': var(--active-color),
  'icon-theme-color': var(--icon-theme-color),
  'red-rgb': var(--red-rgb),
  'light-grey-rgb': var(--light-grey-rgb),
  'border-color-rgb': var(--border-color-rgb),
  'pantone': var(--pantone),
);

// Utility class colours
@mixin originalColors {
  @each $name, $color in $color-palette {
    .bg-#{$name} {
      background: $color;
    }
    .color-#{$name} {
      color: $color;
    }
  }
}

@function get-color($color) {
  @return map-get($color-palette, $color);
}

@include originalColors;

.design-refresh {
  @include originalColors;
}
