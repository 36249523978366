$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1202px,
);

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (min-width: $value) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$breakpoint}` in $breakpoints";
  }
}

@mixin respond-from($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (max-width: $value) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$breakpoint}` in $breakpoints";
  }
}


@mixin respond-from-min-height($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (max-height: $value) {
      @content;
    }
  } @else {
    @warn "Unknown `#{$breakpoint}` in $breakpoints";
  }
}

@mixin hideElementsCollection {
  @each $name, $value in $breakpoints {
    .hide-#{$name} {
      @include respond-from($name) {
        display: none;
      }
    }
  }

  @each $name, $value in $breakpoints {
    .hide-max-height-#{$name} {
      @include respond-from-min-height($name) {
        display: none;
      }
    }
  }
}

@include hideElementsCollection;
