$spacing: 1.6rem;
$xxxs: 0.3 * $spacing;
$xxs: 0.5 * $spacing;
$xs: 0.75 * $spacing;
$sm: 1.25 * $spacing;
$md: 2 * $spacing;
$lg: 3.25 * $spacing;
$xl: 5.25 * $spacing;
$xxl: 8.5 * $spacing;

$spaceProperty: margin padding;

$spacing: (
  'auto': auto,
  'no': 0,
  'xxxs': $xxxs,
  'xxs': $xxs,
  'xs': $xs,
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
  'xs-': -$xs,
  'sm-': -$sm,
  'md-': -$md,
  'lg-': -$lg,
  'xl-': -$xl,
  'xxl-': -$xxl,
);

@mixin createSpacing($property, $screenName: '') {
  @each $sizeName, $size in $spacing {
    @if (
      not
        (
          $property ==
            padding and
            (
              $sizeName ==
                auto or
                $sizeName ==
                xs- or
                $sizeName ==
                sm- or
                $sizeName ==
                md- or
                $sizeName ==
                lg- or
                $sizeName ==
                xl- or
                $sizeName ==
                xxl-
            )
        )
    ) {
      .#{if($screenName != '', $screenName+\:, '')}#{if($property == margin, m, p)} {
        &-#{$sizeName} {
          #{$property}: $size;
        }
        &t-#{$sizeName} {
          #{$property}-top: $size;
        }
        &r-#{$sizeName} {
          #{$property}-right: $size;
        }
        &b-#{$sizeName} {
          #{$property}-bottom: $size;
        }
        &l-#{$sizeName} {
          #{$property}-left: $size;
        }
        &y-#{$sizeName} {
          #{$property}-top: $size;
          #{$property}-bottom: $size;
        }
        &x-#{$sizeName} {
          #{$property}-left: $size;
          #{$property}-right: $size;
        }
      }
    }
  }
}
@mixin originalSpacing {
  @each $property in $spaceProperty {
    // Spacing for all screens
    @include createSpacing($property);
    // Spacing for breakpoints, if they're available
    @if variable-exists(breakpoints) {
      @each $screenName, $screen in $breakpoints {
        @media screen and (min-width: $screen) {
          @include createSpacing($property, $screenName);
        }
      }
    }
  }
}

@include originalSpacing;

.design-refresh {
  @include originalSpacing;
}
