// Material tooltip custom style

.default-tooltip {
  background-color: #f1f6fb !important;
  border: 1px solid #2a498c;
  color: #2a498c !important;
  border-radius: 5px !important;
  white-space: pre-line;
  max-width: 400px !important;
  font-size: 12px !important;
  line-height: 15px;
  box-shadow: 0 0.3rem 0.8rem 0 var(--shadow-blue-color);
}

.recat-tooltip {
  background-color: #323333 !important;
  border: 1px solid var(--white);
  color: var(--white) !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  padding: 10px !important;
  padding-left: 30px !important;
  overflow: initial !important;
  &::after {
    // recat icon
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    background-size: 13px 13px;
    background-image: url('../assets/svgs/recat-icon/lib-recategorisation-icon.component.svg');
    background-repeat: no-repeat;
    left: 10px;
    top: 20px;
  }
  &::before {
    // triangle
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0 4px 6.9px 4px;
    border-color: transparent transparent #323333 transparent;
    transform: rotate(180deg);
    bottom: -5px;
    left: 30px;
  }
}

.selected-customer-tooltip {
  @extend .default-tooltip;
  font-size: 16px !important;
}

.id-doc-tooltip {
  @extend .default-tooltip;
  margin: 0 0 -4px 45px !important;
}

.shift-left {
  position: relative;
  right: 110px;
}
