.circle {
  border-radius: 25px;
  width: 19px;
  height: 19px;

  &.circle__color--red {
    background-color: map-get($color-palette, 'red');
  }

  &.circle__color--light-grey {
    background-color: map-get($color-palette, 'light-grey');
  }

  &.circle__color--grey {
    background-color: map-get($color-palette, 'grey');
  }

  &.circle__color--orange {
    background-color: map-get($color-palette, 'orange');
  }

  &.circle__color--green {
    background-color: map-get($color-palette, 'green');
  }
}
