$margin: 1rem;
$half-margin: calc($margin / 2);
$border-width: 1px;
$border-radius: 0.5rem;
$border-radius-lg: 1rem;
$border-radius-sm: 0.25rem;

$box-shadow-sm: 0 calc(#{$xs} / 4) $xxs 0px map-get($color-palette, 'shadow-color');
$box-shadow-bank: 0 0 8px 0 #dad7d7;

$box-shadow-bottom: 0 calc(#{$xs} / 8) $xxs 0 map-get($color-palette, 'shadow-color');
$box-shadow-top: 0 0 $xxs 0 map-get($color-palette, 'shadow-color');
$borders: (
  'border-width': $border-width,
  'border-color': map-get($color-palette, 'border-color'),
  'border-radius': $border-radius,
  'border-radius-lg': $border-radius-lg,
  'border-radius-sm': $border-radius-sm,
  'box-shadow-sm': $box-shadow-sm,
);
