*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}

[hidden] {
  display: none !important;
}

:root {
  font-size: 62.5%;
  display: block;
}

html {
  overflow-x: hidden;
}

html,
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  height: 100vh;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  color: map-get($color-palette, 'light-blue') !important;
}

.arrow-icon {
  transform: rotate(180deg);
  padding-left: 0px;
}

.no-border {
  border-right: 0px !important;
}

// Insights
.insight-value {
  @extend .pink-color;
  font-weight: 400;
}

.pink-value {
  @extend .pink-color;
  font-weight: 600;
}

.blue-value {
  color: map-get($color-palette, 'bright-blue');
  font-weight: 600;
}

.pink-color {
  color: map-get($color-palette, 'pink');
}

.full-width {
  width: 100%;
}

.half-width {
  flex: 50%;
  box-sizing: border-box;
}

.border-right {
  border-right: 1px solid #ccc;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.panel-title {
  height: 60px;
  display: grid;
}

.no-title {
  svg {
    pointer-events: none;
  }
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.vertical-align-middle {
  vertical-align: middle;
}

.font-weight-normal {
  font-weight: 400;
}

.pagination {
  display: inline-block;
}

.fs-italic {
  font-style: italic;
}

.dropdown-disabled {
  pointer-events: none;
  opacity: 0.5;
}
