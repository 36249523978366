@mixin hideArtifacts($background) {
  &::before,
  &::after {
    content: '';
    height: 100%;
    width: 2px;
    display: block;
    position: absolute;
    top: 0;
    background: $background;
  }
  &::after {
    content: '';
    right: -1px;
  }
  &::before {
    content: '';
    left: -1px;
  }
}

.main {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  background: map-get($color-palette, 'content-bg');
  color: map-get($color-palette, 'main-color');
  // Fixed header height
  padding-top: 16rem;

  &--no-nav-bar {
    padding-top: 8.3rem;
  }

  .main__header,
  .main__footer,
  .main__navigation,
  .page-header,
  .page-header__body,
  .page-header__subnav {
    position: relative;
    flex: 0 0 40px;
    .main__content {
      @include respond-to(xl) {
        width: 80%;
        max-width: map-get($breakpoints, 'xl');
      }
    }
  }

  .main__header {
    z-index: $z-index-main-header;
    box-shadow: $box-shadow-bottom;
    height: 83px;

    .header__partner-logo {
      height: 40px;
      max-width: 160px;
      padding: 4px 0;

      &--square {
        height: 60px;
      }
    }

    &__top-bar {
      background-color: map-get($color-palette, 'content-bg');
      text-align: center;
    }
  }

  .main__footer {
    padding: 14px;
    height: 100%;
    box-shadow: $box-shadow-top;
    text-align: center;
  }

  .main__navigation {
    z-index: $z-index-main-header-nav;
    background-color: $main-nav-bg;

    a {
      display: inline-block;
      margin: 0 $xl 0 0;
      color: #ffffff;
      padding: $xs 0;
      text-decoration: none;
      font-size: $font-size-h6;
      font-weight: $font-weight-base;
      text-align: center;

      &.current-nav-item {
        font-weight: $font-weight-bold;
        box-shadow: 0px 3px 0px 0px map-get($color-palette, 'secondary');
        position: relative;
        @include hideArtifacts($main-nav-bg);
      }
    }
  }

  .main__content {
    margin: 0 auto;
    width: 100%;
    color: map-get($color-palette, 'content-color');
    @include respond-to(xl) {
      width: 80%;
      max-width: 1062px;
    }
  }

  > .main__content {
    flex: 1 0 auto;

    position: relative;
    .content__full-width {
      background: map-get($color-palette, 'content-bg');
      color: map-get($color-palette, 'content-color');
      width: 100vw;
      margin-left: calc(-50vw + 50%);
      position: relative;

      &--arrow {
        &:after {
          content: '';
          border-style: solid;
          border-width: 20px 20px 0 20px;
          border-color: map-get($color-palette, 'content-bg') transparent transparent transparent;
          position: absolute;
          bottom: -20px;
          text-align: center;
          left: calc(50% - 20px);
        }
      }

      &.stick-to-top-subnav {
        position: sticky;
        top: 131px;
        z-index: 2;
        margin-bottom: 15px;
      }
    }

    .stick-to-top-table-header {
      position: sticky;
      top: 183px;
      z-index: 1;
      margin-bottom: 10px;
      height: 52px;
      padding-top: 17px;
    }

    .table-header {
      background: var(--content-bg);
      margin-right: -8px;
      margin-left: -8px;
      border-bottom: 1px solid $main-nav-bg;
    }
  }
  // customer portal override
  &--customer-role {
    padding-top: initial;
    background: map-get($color-palette, 'white');
    lib-spinner .spinner .spinner__spinner-container {
      width: 10rem !important;
      height: 10rem !important;
    }
    ul {
      li {
        right: -0.5rem;
        position: relative;
        width: calc(100% - 1.25rem);
        &:before {
          position: absolute;
          left: -1.2rem;
          content: '•';
          color: map-get($color-palette, 'grey');
          font-weight: bold;
          font-size: 1rem;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-main-header;
}

.page-header {
  z-index: $z-index-page-header;

  .page-header__header {
    padding-bottom: $md;
  }

  .page-header__body {
    padding-bottom: $md;
  }
}

.page-header-subnav {
  z-index: $z-index-page-header-nav;
  border-bottom: 1px solid $subpage-nav-border;
  border-top: 1px solid $subpage-nav-border;

  section {
    width: 100%;
  }

  nav {
    min-height: 52px;
    background-color: $page-nav-bg;
    a {
      color: map-get($color-palette, 'grey');
      text-decoration: none;
      font-size: $font-size-h6;
      font-weight: $font-weight-base;
      text-align: center;
      padding: 0 8px;

      &.current-nav-item {
        color: map-get($color-palette, 'primary');
        font-weight: $font-weight-bold;
        box-shadow: 0px 3px 0px 0px map-get($color-palette, 'secondary');
        position: relative;
        @include hideArtifacts($page-nav-bg);
      }
    }

    lib-button {
      button {
        margin: $xxs 0;
      }
    }
  }
}

.list-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid map-get($color-palette, 'border-color');
  color: map-get($color-palette, 'content-color');
  width: 100%;

  &.list-row--top:nth-child(1) {
    border-top: 1px solid map-get($color-palette, 'border-color');
  }
}

.group-no-last-margin {
  *:not(:last-child) > div {
    margin-bottom: map-get($spacing, 'sm');
  }
}
.group-last-margin {
  * > div {
    margin-bottom: map-get($spacing, 'sm');
  }
}

.overflow-hidden {
  overflow: hidden;
}

.home-icon {
  svg {
    height: 2rem;
    width: 2rem;
  }
}

.list-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid map-get($color-palette, 'border-color');
  color: map-get($color-palette, 'content-color');
}

.list-row__last {
  border-bottom: none;
}

.list-row__details {
  max-width: 45%;
  text-align: right;
  word-wrap: break-word;
}

.columns-pattern {
  display: flex;
  align-items: stretch;
  height: 100%;
  .columns-pattern__main-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    & > .panel {
      height: 100%;
    }
  }
}

.panel-header {
  width: 100%;
  background-color: #e7eaee;
}

.transaction-recat-status {
  margin-right: 10px;
  background: #f47311;
  border-radius: 20px;
  height: 14px;
  width: 14px;
  min-width: 14px;
  color: white;
  font-size: 11px;
  padding: 1px 0 0 6.5px;
}

.report-panel {
  &__cell {
    flex-grow: 1;
    flex-basis: 0;
    &:not(:last-child) {
      border-right: 1px solid map-get($color-palette, 'light-grey');
    }
  }
}

.horizontalline {
  margin: 17px 0 17px 18px;
  max-width: 982px;
  border: 1px solid map-get($color-palette, 'light-grey');
}

.columns {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 2rem;
  row-gap: 2rem;

  @include respond-to(xl) {
    grid-template-columns: repeat(auto-fill, calc(50% - 1rem));
  }
}

.full-width-column {
  grid-template-columns: 100% !important;
}
